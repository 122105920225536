@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap");

:root {
    --primary-color: #047aed;
    --footer-height: 30px;
}

html {
    position: relative;
    min-height: 100%;
}

body {
    font-family: "Lato", sans-serif;
    color: #333;
    line-height: 1.6;
    background-color: #f8f9fc;
    margin-bottom: var(--footer-height);
}

h1,
h2 {
    font-weight: 300;
    line-height: 1.2;
    margin: 10px 0;
}

p {
    margin: 10px 0;
}

html,
body,
#root {
    height: 100%;
}

/* Navbar */
.navbar {
    background-color: var(--primary-color);
    color: #fff;
    height: 70px;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
}

.navbar .logo {
    width: 200px;
}

/* Footer */
footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: var(--footer-height);
    background-color: #eee;
    color: #333;
}

.footer-content {
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    height: 100%;
}

/* Utilities */
.container {
    max-width: 1200px;
    padding: 0 40px;
}

.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.grid-3 {
    grid-template-columns: repeat(3, 1fr);
}

.btn {
    transition: all 0.2s;
    border: none;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.btn-gray {
    background-color: #2f2e41;
}

.btn-outline {
    color: var(--primary-color);
    background-color: #fff;
    border: 1px solid var(--primary-color);
}

.btn:hover {
    transform: scale(0.98);
    color: #fff;
}

/* Bootstrap */
#nav-account-dropdown {
    color: #fff;
}

#nav-dashboard .dropdown-item {
    font-size: 15px;
}

.nav-link {
    padding-left: 10px;
    margin: 0 5px;
}

.navbar-brand {
    margin-top: 5px !important;
}

.navbar-account {
    fill: rgba(255, 255, 255);
}

.navbar-account-name {
    padding-left: 5px;
}

.navbar-account:hover {
    fill: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .show > .nav-link {
    fill: #fff !important;
}

.navbar-account .dropdown-toggle::after {
    display: none;
}

/* Settlements */
.settlements {
    background-color: #fff;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
    border-top: 2px solid var(--primary-color);
}

.settlements .btn {
    width: 100%;
}

.settlements .ebay {
    width: 45px;
    height: 45px;
}

.settlements .action {
    width: 20px;
    margin-right: 10px;
    fill: #6c757d;
}

.settlements .btn {
    box-shadow: none;
}

#settlements-table th {
    text-align: center;
    color: #333;
    font-size: 14px;
    border-top: none;
    border-bottom: 1px solid #f1f1f1;
}

#settlements-table td {
    text-align: center;
    color: #6c757d;
    font-size: 14px;
    vertical-align: middle;
}

.settlements-spinner {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}

.settlements-spinner .spinner-border {
    color: var(--primary-color);
    border: 0.2em solid currentColor;
    border-right-color: transparent;
}

.settlements-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    border-bottom: 1px solid #f1f1f1;
}

.settlements-search {
    width: 30%;
    height: 35px;
    float: right;
}

.settlements-help {
    text-align: center;
    color: #6c757d;
}

#nav-channel-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 0.25rem;
}

.channel-dropdown a {
    color: #333 !important;
}

.channels-header {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;
    border-top: 2px solid var(--primary-color);
}

.channels-name {
    margin-left: 10px;
}

.channels-header span {
    font-size: 18px;
    color: #6c757d;
    white-space: nowrap;
    display: inline-block;
}

.channels-header span:focus {
    outline: none;
}

.channels-header span br {
    display: none;
}

.channels-steps {
    color: #6c757d;
}

.channels-steps .card {
    border: none;
    border-radius: 0;
    border-top: 2px solid var(--primary-color);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.channels-steps .card-title {
    color: var(--primary-color);
    display: flex;
    justify-content: space-between;
}

.channels-card-step {
    font-weight: bold;
}

.channels-card-step img {
    margin-left: 10px;
}

.channels-steps .btn {
    box-shadow: none;
}

.channels-accounting {
    padding-left: 10px;
    padding-right: 10px;
}

.channels-accounting ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.channels-accounting li {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #d8d8d8;
}

/* Login */
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.login-logo {
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.login-logo img {
    width: 300px;
}

.login-main {
    width: 450px;
    padding-bottom: 80px;
}

.login .card {
    border: none;
    border-radius: 0;
    border-top: 2px solid var(--primary-color);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
}

.login .card-title {
    color: var(--primary-color);
    text-align: center;
}

.login .card-text {
    text-align: center;
    color: #6c757d;
}

.login-card-bottom {
    display: flex;
    justify-content: center;
}

.login-platforms {
    padding-left: 10px;
    padding-right: 10px;
}

.login-platforms ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.login-platforms li {
    display: flex;
    justify-content: center;
    padding: 10px;
}

/* Mobile */
@media (max-width: 500px) {
    .login .grid {
        grid-template-columns: repeat(1, 1fr);
    }

    .login-img {
        display: none;
    }

    .login .container {
        height: auto;
        position: absolute;
        top: 35%;
        bottom: 35%;
    }
}
